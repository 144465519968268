@import "colors";

:root {
  @each $name, $color in $project-colors {
    --color-#{$name}: #{$color};
  }
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 0.5rem;
}

.ant-table-pagination {
  justify-content: center !important;
}
