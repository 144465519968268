
// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@primary-color: #84C225;

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
}

.ant-steps-item-icon {
    height: auto;
}

nz-date-picker {
    width: 100%;
}

nz-table {
    overflow-x: auto;
}

.ant-table-title {
    font-size: 1rem;
    font-weight: 600;
}

.ant-dropdown-menu {
    li {
        i {
            margin-right: 0.5rem;
        }
    }
}

.ant-page-header {
    padding: 0;
    margin-bottom: 2rem;

    &-heading-left {
        margin: 0;
    }

    &-heading-sub-title, &-heading-extra {
        margin: 0;
        line-height: normal;
    }
}